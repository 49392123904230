import { useMemo } from 'react';
import { useCurrentOrganisation } from '#pie/auth';
import { isMenuItemAllowed, menu } from '#pie/components/layout/sidebar/menu/menu';
import { useStoredParams } from '#pie/stores/searchParams';

export const useProtectedMenu = () => {
  const { currentAccount } = useCurrentOrganisation();
  const storedTicketFilterParams = useStoredParams('/tickets');
  const computedMenu = menu({ ticketFilterParams: storedTicketFilterParams });

  const mainMenu = useMemo(
    () => computedMenu.main.filter(menuItem => isMenuItemAllowed(menuItem, currentAccount)),
    [computedMenu.main, currentAccount?.roles]
  );

  return { mainMenu };
};
