import IconCable from '@iconify/icons-material-symbols/cable';
import IconCellWifi from '@iconify/icons-material-symbols/cell-wifi';
import IconContactPage from '@iconify/icons-material-symbols/contact-page-outline';
import IconHotelClass from '@iconify/icons-material-symbols/hotel-class-outline';
import IconLightbulb from '@iconify/icons-material-symbols/lightbulb-outline';
import IconList from '@iconify/icons-material-symbols/list';
import IconLocalLibrary from '@iconify/icons-material-symbols/local-library-outline';
import IconSchool from '@iconify/icons-material-symbols/school-outline';
import { type AccountAuthorisationDto, AuthorizationRoles } from '#edsn/api/pie-bff';
import { hasRole } from '#edsn/auth';
import type { Menu } from './SidebarMenu';
import { TicketCounter } from '#pie/components/ticket-counter/TicketCounter';

export const isLinkBeta = (link: Menu['to']) => !link.includes('http');

export const isMenuItemAllowed = (menuItem: Menu, currentAccount?: AccountAuthorisationDto): boolean => {
  const isMenuUnrestricted = !menuItem.allowedRoles;
  const userHasRole =
    currentAccount?.roles && menuItem.allowedRoles && hasRole(menuItem.allowedRoles, currentAccount.roles);

  return isMenuUnrestricted || !!userHasRole;
};

export const menu = ({ ticketFilterParams }: { ticketFilterParams?: string }): { main: Menu[] } => ({
  main: [
    {
      adornment: isOpen => <TicketCounter isOpen={isOpen} />,
      allowedRoles: ['Tickets'],
      description: 'Bekijk en beheer tickets',
      exactMatch: false,
      icon: IconList,
      label: 'Tickets',
      to: `/tickets${ticketFilterParams ?? ''}`,
    },
    {
      allowedRoles: ['Tickets'],
      description: 'Bekijk de ticket feedback',
      exactMatch: false,
      icon: IconHotelClass,
      label: 'Ticket feedback',
      to: `/ticketfeedback`,
    },
    {
      allowedRoles: ['KnowledgeArticles'],
      description: 'Vind antwoorden op je vragen',
      exactMatch: true,
      icon: IconSchool,
      label: 'Kennisbank',
      to: '/kennisbank',
    },
    {
      description: 'Lees het laatste nieuws',
      exactMatch: false,
      icon: IconLocalLibrary,
      label: 'Nieuws',
      to: '/nieuws',
    },
    {
      description: 'Hoe zou jij partners in energie verbeteren?',
      exactMatch: false,
      icon: IconLightbulb,
      label: 'Ideeën',
      to: 'https://mijn.partnersinenergie.nl/nl-NL/ideeen/',
    },
    {
      allowedRoles: [AuthorizationRoles.CprRead],
      description: 'Bekijk de contactpersonen',
      exactMatch: false,
      icon: IconContactPage,
      label: 'Contactpersonen-register',
      to: '/contactpersonenregister',
    },
    {
      description: 'Bekijk informatie over de aansluitingen',
      exactMatch: false,
      icon: IconCable,
      label: 'Aansluitingsinfo',
      to: 'https://mijn.partnersinenergie.nl/nl-NL/aansluitingsinformatie/',
    },
    {
      children: [
        {
          label: 'Data-diensten',
          to: 'https://mijn.partnersinenergie.nl/nl-NL/dataverzoek/',
        },
        {
          label: 'Aanvraagformulier',
          to: 'https://mijn.partnersinenergie.nl/nl-NL/dataverzoek-aanvraagformulier/',
        },
      ],
      description: 'Vraag data aan',
      exactMatch: false,
      icon: IconCellWifi,
      label: 'Data-diensten',
      to: 'https://mijn.partnersinenergie.nl/nl-NL/dataverzoek/',
    },
  ],
});
