import { Skeleton } from '../skeleton/Skeleton';
import { Text } from '../text/Text';
import type { VariantProps } from '#pie/utils/TailwindUtils';
import { tv } from '#pie/utils/TailwindUtils';

interface AvatarProps extends VariantProps<typeof styles> {
  image?: string;
  initials?: string;
  name?: string;
  company?: string;
  className?: string;
  showInfo?: boolean;
  isLoading?: boolean;
}

export const Avatar = ({
  name,
  company,
  initials,
  variant,
  image,
  size,
  className,
  showInfo,
  isLoading,
}: AvatarProps) => {
  const s = styles({ size, variant });
  const hasInfo = !!company && !!name;

  return (
    <div className={s.base({ className })}>
      {isLoading ? (
        <Skeleton className={s.skeleton()} />
      ) : image ? (
        <img alt={name} className={s.image()} src={image} />
      ) : (
        <div className={s.image()}>{initials}</div>
      )}

      {hasInfo && showInfo && (
        <div>
          <Text as="div" variant={size === 32 ? 'sm' : undefined} className={s.company()}>
            {isLoading ? <Skeleton className="w-[3rem]" /> : company}
          </Text>
          <Text as="div" variant="xs" className={s.name()}>
            {isLoading ? <Skeleton className="w-[3rem]" /> : name}
          </Text>
        </div>
      )}
    </div>
  );
};

export const styles = tv({
  defaultVariants: {
    size: 48,
  },

  slots: {
    base: 'flex items-center',
    company: 'font-bold',
    image: 'rounded-full bg-primary-dark grid place-items-center text-white',
    name: 'text-neutral-500',
    skeleton: '',
  },

  variants: {
    size: {
      120: {
        base: 'gap-3',
        image: 'w-30 h-30 text-display',
        skeleton: 'w-30 h-30',
      },

      32: {
        base: 'gap-3',
        image: 'w-8 h-8 text-h6',
        skeleton: 'w-8 h-8',
      },

      48: {
        base: 'gap-4',
        image: 'w-12 h-12 text-h6',
        skeleton: 'w-12 h-12',
      },

      64: {
        base: 'gap-4',
        image: 'w-16 h-16 text-h6',
        skeleton: 'w-16 h-16',
      },
    },

    variant: {
      white: {
        company: 'text-white',
        name: 'text-white',
      },
    },
  },
});
