import IconFeedback from '@iconify/icons-material-symbols/feedback-outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '../../dialog/Dialog';
import { styles } from '../menu/SidebarMenu';
import { Button } from '#pie/components/button/Button';
import { Icon } from '#pie/components/icon/Icon';
import { Text } from '#pie/components/text/Text';

interface Props {
  isOpen?: boolean;
}

export const FeedbackDialog = ({ isOpen }: Props) => {
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const { t } = useTranslation();
  const s = styles({ isOpen });

  return (
    <>
      <div className="relative mt-auto flex gap-3">
        <button onClick={() => setFeedbackOpen(true)} aria-label="Feedback" className={s.item()}>
          <Icon icon={IconFeedback} className="shrink-0" />
          {isOpen && 'Feedback'}
          <span className={s.adornment()} />

          <Text variant="xs" className={s.beta()}>
            {isOpen && 'Beta'}
          </Text>
        </button>
      </div>
      <Dialog open={feedbackOpen} size="md" className="text-center" onOpenChange={setFeedbackOpen}>
        <div className="mx-auto mb-1 grid h-14 w-14 items-center justify-center rounded-full bg-neutral-100">
          <Icon icon={IconFeedback} size={32} className="text-primary" />
        </div>

        <Text variant="h4" className="text-primary-dark mb-3">
          {t('feedback_dialog.title')}
        </Text>

        <Text className="mb-6">
          {t('feedback_dialog.description')}{' '}
          <a
            href="mailto:servicedesk@edsn.nl?subject=Bevinding%20Beta%20PiE%20&body=We%20ontvangen%20graag%20de%20volgende%20informatie:%0A%0A%E2%80%A2%20Organisatie%20EAN%0A%0A%E2%80%A2%20E-mailadres%20van%20gebruikersaccount%20PiE%20indien%20anders%20dan%20verzender"
            className="text-primary link"
          >
            {t('feedback_dialog.common_email_servicedesk')}
          </a>
        </Text>

        <div className="flex gap-4">
          <Button variant="ghost" className="flex-1" onClick={() => setFeedbackOpen?.(false)}>
            {t('common.button.cancel')}
          </Button>

          <Button
            as="a"
            variant="primary"
            className="flex-1"
            href={'https://edsn.atlassian.net/servicedesk/customer/portal/3'}
            target="_blank"
          >
            {t('feedback_dialog.button.selfserviceportal')}
          </Button>
        </div>
      </Dialog>
    </>
  );
};
