import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getCprManagementContact } from '#edsn/api/pie-bff';
import { Card } from '#pie/components/card/Card';
import { DataTable } from '#pie/components/data-table/DataTable';
import { InputSearch } from '#pie/components/input-search/InputSearch';
import { Stack } from '#pie/components/stack/Stack';
import { useDataTable } from '#pie/hooks/useDataTable';
import { cprManagementContactSortingAtom } from '#pie/stores/cprManagementContact';
import { searchAtom } from '#pie/stores/search';
import { emptyFilterAtom } from '#pie/stores/searchOnlyFilter';

export const ContactManagement = () => {
  const { t } = useTranslation();

  const tableProps = useDataTable({
    columns: helper => [
      helper.accessor('contactGroup.name', {
        cell: info => (
          <>
            <Link className="text-primary link" to={info.row.original.contactGroup.id} aria-label={info.getValue()}>
              {info.getValue()}
            </Link>
            <Link className="absolute inset-0" to={info.row.original.contactGroup.id} />
          </>
        ),
        header: t('common.contact_group'),
      }),
      helper.accessor('contactGroup.isRequired', {
        cell: info => <>{info.getValue() ? t('common.yes') : t('common.no')}</>,
        header: t('common.required'),
      }),
      helper.accessor('name', {
        header: t('common.name'),
      }),
      helper.accessor('email', {
        header: t('common.email'),
      }),
      helper.accessor('phone', {
        header: t('common.phone'),
      }),
    ],
    dateKeys: [],
    filtersAtom: emptyFilterAtom,
    query: getCprManagementContact,
    sortingAtom: cprManagementContactSortingAtom,
  });

  const [search, setSearch] = useAtom(searchAtom);

  return (
    <section className="flex items-start gap-6">
      <Card className="flex-1 overflow-x-hidden p-4">
        <Stack gap="md">
          <header className="px-3" role="toolbar">
            <InputSearch
              value={search || ''}
              onChange={e => setSearch(e.target.value)}
              placeholder={t('common.search.placeholder')}
              onClear={() => setSearch('')}
            />
          </header>
          <DataTable {...tableProps} idKey="id" />
        </Stack>
      </Card>
    </section>
  );
};
