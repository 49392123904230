export const fetchOptions: {
  getOrganisationEan: () => string | null | Promise<string | null>;
} = {
  getOrganisationEan: () => null,
};

export async function fetchInstance<T>({
  url,
  method,
  params,
  headers: { 'Content-Type': contentType, ...headers } = {},
  data,
  withEan = true,
}: {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  headers?: Record<string, string>;
  // Generated types are wrong
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any;
  data?: unknown;
  responseType?: string;
  signal?: AbortSignal;
  withEan?: boolean;
}): Promise<T> {
  const orgEan = await fetchOptions.getOrganisationEan();
  const host = import.meta.env.VITE_API_URI;
  const res = await fetch(
    `${!url.match('://') && host ? host : ''}${url}${params ? `?${new URLSearchParams(params)}` : ''}`,
    {
      headers: {
        ...headers,
        // Content-Type should not be set to multipart/form-data as it is set by the FormData object, see: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest_API/Using_FormData_Objects#sect4
        ...(!contentType || contentType === 'multipart/form-data' ? {} : { 'Content-Type': contentType }),
        ...(orgEan && withEan ? { 'x-edsn-organisation-ean': orgEan } : null),
      },
      method,
      ...(data ? { body: data instanceof FormData ? data : JSON.stringify(data) } : {}),
    }
  );

  if (!res.ok) {
    throw res;
  }

  return res.headers.get('Content-Type')?.match(/application\/json/)
    ? res.json()
    : res.headers.get('Content-Type')?.match(/text\/plain/) || !res.headers.get('Content-Type')
      ? res.text()
      : res.blob();
}
