import { useBlocker } from 'react-router-dom';
import { Button } from '../button/Button';
import { Dialog } from '../layout/dialog/Dialog';
import { Stack } from '../stack/Stack';
import { Text } from '../text/Text';

export const CancelCreateTicketDialog = ({
  shouldBlock,
  ticketName,
}: {
  shouldBlock: boolean;
  ticketName?: string;
}) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => shouldBlock && currentLocation.pathname !== nextLocation.pathname
  );

  return (
    <Dialog open={blocker.state === 'blocked'} title="Ticket aanmaken annuleren" onOpenChange={() => blocker.reset?.()}>
      <Text className="mb-6">
        Je gaat het aanmaken van {ticketName ? '' : 'deze'} ticket {ticketName} annuleren. Weet je dit zeker?
      </Text>
      <Stack direction="row" gap="md">
        <Button className="flex-1" onClick={() => blocker.reset?.()} type="submit" variant="ghost">
          Behouden
        </Button>
        <Button className="flex-1" onClick={() => blocker.proceed?.()} variant="danger">
          Ticket annuleren
        </Button>
      </Stack>
    </Dialog>
  );
};
