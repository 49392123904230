import type { ContactDto, ContactDtoListResultDto } from '../generated/model';

export const createContacts = (): ContactDtoListResultDto => ({
  items: [
    createContact(),
    createContact({
      email: 'pdevries@dexample.com',
      fullName: 'Piet de Vries',
      id: 'd34e5b5d-43ff-4cac-9087-3f843bd2c738',
    }),
  ],
  totalCount: 2,
});

export const createContact = (overrides: Partial<ContactDto> = {}): ContactDto => ({
  email: 'jdejong@dexample.com',
  firstName: 'Jan',
  fullName: 'Jan de Jong',
  id: 'afd5e7af-2681-a5dc-3902-6c75cc096a98',
  lastName: 'jong',
  phone: '0612341234',
  ...overrides,
});
