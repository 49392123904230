import { Page, PageHeader } from '#pie/components/page/Page';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';

export const ReleaseNotes = () => (
  <Page>
    <PageHeader title="Release Notes" />
    <div className="container">
      <Stack divider="line" gap="lg">
        <Stack className="gap-8">
          <Text as="h1" variant="h2">
            Versie 0.3.1
          </Text>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Nieuwe Features
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>CPR beheerders kunnen nu nieuwe contactpersonen aanmaken.</li>
                <li>CPR beheerders kunnen contactpersonen nu bijwerken.</li>
                <li>
                  CPR beheerders kunnen nu bij het toevoegen van een contactpersoon aan de contactgroep, zoeken op een
                  contactpersoon.
                </li>
                <li>
                  Lange onderwerpen bij tickets worden nu afgekapt en hier kan overheen gehovert worden om de volledige
                  titel te bekijken.
                </li>
              </ul>
            </Text>
          </Stack>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Verbeteringen
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>Ontwerp van contactpersonenregister verbeterd en tabjes hernoemd.</li>
                <li>
                  Er is een venster gekomen wanneer op feedback geven geklikt wordt, welke verwijst naar een e-mailadres
                  of de service desk.
                </li>
              </ul>
            </Text>
          </Stack>
        </Stack>

        <Stack className="gap-8">
          <Text as="h1" variant="h2">
            Versie 0.3.0
          </Text>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Nieuwe Features
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>Het contactpersonenregister is nu beschikbaar samen met het contactpersonen beheer.</li>
              </ul>
            </Text>
          </Stack>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Bugfixes
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>Problemen met de chat widget zijn verholpen.</li>
                <li>Het downloaden van bijlages werkt weer.</li>
              </ul>
            </Text>
          </Stack>
        </Stack>

        <Stack className="gap-8">
          <Text as="h1" variant="h2">
            Versie 0.2.3
          </Text>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Bugfixes
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>Validatie bij het aanmaken van een non-conformiteit ticket is verbeterd.</li>
                <li>Probleem verholpen waarbij het sorteren niet altijd werkte.</li>
                <li>
                  Links worden nu ook weergegevens als deze door de netbeheerder opgevoerd worden bij een reactie op een
                  ticket.
                </li>
              </ul>
            </Text>
          </Stack>
        </Stack>

        <Stack className="gap-8">
          <Text as="h1" variant="h2">
            Versie 0.2.2
          </Text>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Verbeteringen
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>
                  De optie "Geen" is nu ook aan de team select toegevoegd, zodat er ook op tickets gefilterd kan worden
                  zonder team.
                </li>
                <li>In de aansluitingsinformatie is nu altijd de EAN te zien die vanuit CTS wordt meegestuurd.</li>
              </ul>
            </Text>
          </Stack>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Bugfixes
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>
                  Behandelaarlijst op ticket bevat nu het tussenvoegsel van de naam en de lijst staat gesoteerd op
                  alfabetische volgorde.
                </li>
                <li>Bij het sorteren worden kolommen nu altijd eerst aflopend gesorteerd.</li>
                <li>Tabel dat door RNB in een reactie op een ticket wordt toegevoegd, is nu ook zichtbaar.</li>
                <li>
                  Vertalingen van het oorsprong filter op de ticketpagina zijn toegevoegd, waardoor hier de juiste tekst
                  komt te staan.
                </li>
                <li>
                  Probleem opgelost waarbij reacties op tickets die via bèta werden aangemaakt, niet zichtbaar waren op
                  het oude portaal.
                </li>
              </ul>
            </Text>
          </Stack>
        </Stack>

        <Stack className="gap-8">
          <Text as="h1" variant="h2">
            Versie 0.2.1
          </Text>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Bugfixes
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>
                  Probleem verholpen waarbij de chatwidget niet altijd zichtbaar werd en waar de netbeheerder soms niet
                  kon zien met wie ze aan het chatten waren.
                </li>
              </ul>
            </Text>
          </Stack>
        </Stack>

        <Stack className="gap-8">
          <Text as="h1" variant="h2">
            Versie 0.2.0
          </Text>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Nieuwe Features
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>
                  Gesprek omtrent de feedback op tickets over de afhandeling hiervan (voorheen CES), is nu ook
                  beschikbaar op bèta!
                </li>
              </ul>
            </Text>
          </Stack>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Verbeteringen
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>
                  Ticket teller loopt nu tot 999+ en heeft een andere kleur gekregen om deze duidelijker te maken.
                </li>
                <li>EAN-codes zijn nu zichtbaar tijdens het aanmaken van een ticket.</li>
                <li>Na het uitloggen land je nu op de publieke website.</li>
                <li>
                  Wanneer er een foutmelding wordt weergegeven, heb je nu de mogelijkheid om naar de homepage te
                  navigeren.
                </li>
                <li>Als je sessie verlopen is, kom je nu op een pagina waar je opnieuw kunt inloggen.</li>
              </ul>
            </Text>
          </Stack>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Bugfixes
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>
                  Bug opgelost waarbij netbeheerder niet automatisch werd ingevuld bij het invullen van een gas EAN,
                  terwijl deze informatie wel vanuit CTS kwam.
                </li>
                <li>
                  De chatwidget wordt nu alleen getoond als deze geladen is en gekoppeld is aan een gebruikersaccount,
                  welke de bug waarbij netbeheerders niet zagen met wie zij aan het chatten waren, zou moeten oplossen.
                </li>
              </ul>
            </Text>
          </Stack>
        </Stack>

        <Stack className="gap-8">
          <Text as="h1" variant="h2">
            Versie 0.1.0
          </Text>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Nieuwe Features
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>Gemakkelijk zoeken in kennisbankartikelen met de nieuwe zoekbalk.</li>
                <li>
                  Gerelateerde kennisbankartikelen verschijnen nu bij het aanmaken van tickets, op de
                  ticketdetailpagina, de kennisbankdetailpagina en bij nieuwsitems.
                </li>
                <li>Kennisbankartikelen zijn nu gemakkelijker te lezen.</li>
              </ul>
            </Text>
          </Stack>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Verbeteringen
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>De nieuwsdetailpagina heeft een nieuw uiterlijk gekregen.</li>
                <li>Het onderwerp invoerveld wordt nu automatisch ingevuld bij een Non-Conformiteit ticket.</li>
                <li>Zoekbalk toegevoegd aan de filters op de ticketpagina wanneer er meer dan 15 resultaten zijn.</li>
              </ul>
            </Text>
          </Stack>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Bugfixes
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>Sorteren op categorie werkt nu correct in de kennisbank.</li>
                <li>
                  Probleem verholpen waarbij de behandelaar en de gelezen/ongelezen status niet meteen werden bijgewerkt
                  in de ticketlijsten.
                </li>
                <li>
                  Bug opgelost waarbij je naar een foutpagina werd gestuurd wanneer je op de terug-knop op de
                  ticketpagina klikte.
                </li>
              </ul>
            </Text>
          </Stack>
        </Stack>
        <Stack className="gap-8">
          <Text as="h1" variant="h2">
            Versie 0.0.5
          </Text>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Nieuwe features
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>Zoeken op nieuws artikelen is toegevoegd.</li>
                <li>Ticket teller toegevoegd om te tonen hoeveel ongelezen tickets er open staan.</li>
                <li>Non-conformiteitstickets kunnen nu aangemaakt worden in de nieuw ticket wizard.</li>
                <li>
                  Kennisbankartikelen zijn nu beschikbaar op de kennisbank pagina en kunnen op categorie worden
                  gefilterd.
                </li>
              </ul>
            </Text>
          </Stack>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Verbeteringen
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>We houden nu bij welke acties vaak worden gebruikt door middel van Google Analytics.</li>
              </ul>
            </Text>
          </Stack>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Bugfixes
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>
                  Bug opgelost waarbij tijdens het aanmaken van een ticket EAN verplicht was nadat deze verkeerd was
                  ingevuld, terwijl het andere veld wel al was ingevuld.
                </li>
              </ul>
            </Text>
          </Stack>
        </Stack>
        <Stack className="gap-8">
          <Text as="h1" variant="h2">
            Versie 0.0.4
          </Text>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Nieuwe features
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>Nieuws overzicht pagina filtering en sortering.</li>
                <li>
                  Inhoudsopgave voor nieuwsdetail bericht toegevoegd en style aanpassingen gedaan om overeen te komen
                  met design.
                </li>
              </ul>
            </Text>
          </Stack>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Bugfixes
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>Na het aanmaken van een ticket kom je niet meer terecht op een leeg scherm.</li>
                <li>Bug waarbij gebruiker niet getoond werd in de chat opgelost.</li>
              </ul>
            </Text>
          </Stack>
        </Stack>
        <Stack className="gap-8">
          <Text as="h1" variant="h2">
            Versie 0.0.3
          </Text>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Nieuwe features
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>
                  Notificatie banner toegevoegd, zodat gebruiker geïnformeerd kan worden wanneer er informatie of
                  problemen gemeld moeten worden op de website.
                </li>
                <li>Onze nieuwe home pagina met een eenvoudige manier om door de site te navigeren.</li>
                <li>Ook het laatste nieuws is te zien op de home pagina.</li>
              </ul>
            </Text>
          </Stack>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Verbeteringen
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>Styling van checkboxes is verbeterd.</li>
                <li>Ticket filters worden nu beter vast gehouden als je door de site heen navigeert.</li>
                <li>
                  Update van de bulk acties 'behandelaar toewijzen' en 'team toewijzen', om het overeen te laten komen
                  met het design van de website.
                </li>
                <li>Zoekbalk in de header is verwijderd aangezien deze geen functie had.</li>
                <li>Bij afgehandeld, afgesloten en geannuleerde tickets kan er geen comment meer worden geplaatst.</li>
                <li>
                  EAN codes die bij tickets horen zijn nu altijd zichtbaar, ook als een gebruiker geen rechten heeft tot
                  aansluitngsinformatie.
                </li>
              </ul>
            </Text>
          </Stack>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Bugfixes
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>Forceer een login als de gebruiker met een eHerkenning token automatisch probeert in te loggen.</li>
              </ul>
            </Text>
          </Stack>
        </Stack>
        <Stack className="gap-8">
          <Text as="h1" variant="h2">
            Versie 0.0.2
          </Text>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Nieuwe features
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>EAN-nummers kunnen nu eenvoudig worden gekopieerd vanuit het ticketdetailscherm.</li>
                <li>Bulkactie toegevoegd aan het ticketoverzicht voor het toewijzen aan een behandelaar.</li>
                <li>Bulkactie toegevoegd aan het ticketoverzicht voor het toewijzen aan een team.</li>
              </ul>
            </Text>
          </Stack>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Verbeteringen
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li>De overgang tussen het inlogscherm en 'Mijn Partners in Energie' is verfijnd.</li>
                <li>Pagina's zijn nu voorzien van paginatitels voor een betere navigatie.</li>
                <li>Google Analytics is geïntegreerd om inzicht te krijgen in het gebruik van de website.</li>
                <li>Datums worden nu op een consistente wijze weergegeven op het ticketdetailscherm.</li>
                <li>De website is nu vertaalbaar voor een breder publiek.</li>
                <li>Formulieren worden nu op een verbeterde manier opgezet.</li>
                <li>Diverse verbeteringen in de algehele gebruikerservaring.</li>
              </ul>
            </Text>
          </Stack>
          <Stack gap="sm">
            <Text as="h2" variant="h4">
              Bugfixes
            </Text>
            <Text as="div">
              <ul className="list-disc space-y-1">
                <li> Gebruikers in Dynamics kunnen nu zien met wie er wordt gechat in de bètaversie.</li>
                <li>Het CES-formulier werkt nu naar behoren.</li>
              </ul>
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </div>
  </Page>
);
