import expandMoreRounded from '@iconify/icons-material-symbols/expand-more-rounded';
import * as Popover from '@radix-ui/react-popover';
import { Command } from 'cmdk';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../icon/Icon';
import { InputSearch } from '../input-search/InputSearch';
import { styles } from '../input-text/InputText';
import { menuStyles } from '../menu/Menu';
import { Skeleton } from '../skeleton/Skeleton';
import { Stack } from '../stack/Stack';
import type { VariantProps } from '#pie/utils/TailwindUtils';
import type { ComponentProps } from 'react';
import { cn, tv } from '#pie/utils/TailwindUtils';

export type Option = {
  label: string;
  value: string;
};

export interface Props
  extends Omit<ComponentProps<'input'>, 'onChange'>,
    Pick<ComponentProps<typeof Command>, 'shouldFilter'>,
    VariantProps<typeof inputCombobox> {
  label: string;
  id?: string;
  options?: Option[];
  isLoading?: boolean;
  disabled?: boolean;
  isError?: boolean;
  required?: boolean;
  onChange?: (value: string) => void;
  onSearch?: (search?: string) => void;
}

export const InputCombobox = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      disabled,
      fullWidth,
      isError,
      isLoading,
      label,
      onChange,
      onSearch,
      options,
      placeholder,
      shouldFilter,
      value,
      ...props
    },
    ref
  ) => {
    const s = inputCombobox({ disabled, fullWidth, isError });
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState<string>();
    const [selectedOption, setSelectedOption] = useState<Option | undefined>(
      options?.find(option => option.value === value)
    );

    const onSearchChange = (search?: string) => {
      setSearch(search);
      onSearch?.(search);
    };

    return (
      <Popover.Root open={open} onOpenChange={setOpen} modal={false}>
        <Popover.Trigger className={s.input()} role="combobox" aria-label={label}>
          <input ref={ref} type="hidden" value={selectedOption?.value || ''} {...props} />

          {selectedOption?.label || <span className="text-neutral-400">{placeholder}</span>}
          <span className={cn(s.icon(), s.iconEnd())}>
            <Icon icon={expandMoreRounded} size={16} />
          </span>
        </Popover.Trigger>
        <Popover.Content
          className={cn(
            s.content(),
            'max-h-[var(--radix-popper-available-height)] min-w-[var(--radix-popper-anchor-width)] overflow-y-auto'
          )}
          align="start"
          side="bottom"
          avoidCollisions={false}
          collisionPadding={16}
        >
          <Command shouldFilter={shouldFilter} className={cn(s.base(), className)}>
            <Stack divider="line" gap="sm">
              <Command.Input
                onValueChange={onSearchChange}
                placeholder={t('common.search.placeholder')}
                value={search}
                asChild
              >
                <InputSearch fullWidth isLoading={isLoading} />
              </Command.Input>
              <Command.List>
                <Command.Group>
                  <Stack as="ul" divider="line">
                    {isLoading &&
                      new Array(3).fill(null).map((_, index) => (
                        <div className={s.item()} key={`skeleton-${index}`}>
                          <Skeleton />
                        </div>
                      ))}
                    {!isLoading && (
                      <Command.Empty className={cn(s.item(), 'text-neutral-400')}>No results.</Command.Empty>
                    )}
                    {!isLoading &&
                      options?.map(option => (
                        <Command.Item
                          value={option.value}
                          key={option.value}
                          className={cn(s.item(), 'data-[selected=true]:bg-neutral-200')}
                          onSelect={() => {
                            setOpen(false);
                            onChange?.(option.value);
                            setSelectedOption(option);
                            onSearchChange(option.label);
                          }}
                        >
                          {option.label}
                        </Command.Item>
                      ))}
                  </Stack>
                </Command.Group>
              </Command.List>
            </Stack>
          </Command>
        </Popover.Content>
      </Popover.Root>
    );
  }
);

const inputCombobox = tv({
  base: '',
  extend: styles,
  slots: {
    content: menuStyles().content(),
    icon: menuStyles().icon(),
    input:
      'flex justify-between pl-4 pr-2 py-[11px] items-center data-[state=open]:border-primary data-[state=open]:ring-primary-lighter data-[state=open]:ring-2 gap-2',
    item: menuStyles().item(),
    value: 'overflow-hidden text-ellipsis whitespace-nowrap',
  },
  variants: {},
});
