/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type AuthorizationRoles = (typeof AuthorizationRoles)[keyof typeof AuthorizationRoles];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthorizationRoles = {
  Tickets: 'Tickets',
  Admin: 'Admin',
  KnowledgeArticles: 'KnowledgeArticles',
  CprRead: 'CprRead',
  CprAdmin: 'CprAdmin',
} as const;
