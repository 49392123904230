import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useGetMainCategory } from '#edsn/api/pie-bff';
import { validation } from '#pie/components/form/formHelpers';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const categoriesValidationSchema = z.object({
  mainCategoryId: z.string(),
  subCategoryId: z.string(),
});

type CategoriesForm = z.infer<typeof categoriesValidationSchema>;

const { FormDropdown } = typedFormFields<CategoriesForm>();

export const TicketCategoriesFields = () => {
  const { t } = useTranslation();
  const { watch, resetField } = useFormContext<CategoriesForm>();
  const { data: categoryData, isLoading: isLoadingCategories } = useGetMainCategory();
  const filteredCategoryItems = useMemo(
    () => categoryData?.items.filter(c => c.name !== 'Non conformiteit'),
    [categoryData]
  );
  const category = watch('mainCategoryId');

  const subCategories = useMemo(
    () => filteredCategoryItems?.find(c => c.id === category)?.subCategories,
    [category, filteredCategoryItems]
  );

  return (
    <>
      <FormDropdown
        label={t('common.main_category')}
        fullWidth
        name="mainCategoryId"
        options={
          filteredCategoryItems?.map(cat => ({
            label: cat.name,
            value: cat.id,
          })) || []
        }
        rules={{ required: validation.required }}
        isLoading={isLoadingCategories}
        onChange={() => {
          resetField('subCategoryId');
        }}
      />

      <FormDropdown
        key={category}
        fullWidth
        label={t('common.sub_category')}
        name="subCategoryId"
        options={
          subCategories?.map(subCat => ({
            label: subCat.name,
            value: subCat.id,
          })) || []
        }
        rules={{ required: validation.required }}
        disabled={!category}
        isLoading={isLoadingCategories}
        placeholder={category ? t('common.dropdown.select') : t('ticket_create.categories.select_main_category_first')}
      />
    </>
  );
};
