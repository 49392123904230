import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { type AccountAuthorisationDto, fetchOptions, useGetUserOrganisationInformation } from '#edsn/api/pie-bff';

export const useCurrentOrganisation = () => {
  const { data: accounts } = useGetUserOrganisationInformation();
  const [currentAccount, _setCurrentAccount] = useState<AccountAuthorisationDto | undefined>();
  const [currentAccountId, setCurrentAccountId] = useLocalStorage<string | undefined>('current-account:pie', undefined);
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const setCurrentAccount = (account?: AccountAuthorisationDto) => {
    fetchOptions.getOrganisationEan = () => account?.ean13 ?? null;
    _setCurrentAccount(account);
  };

  // Clear current account when there are no accounts (this is handled elsewhere)
  // Set first account in list as current account when:
  // - There is no current account
  // - The current account is not in the list
  useEffect(() => {
    if (accounts) {
      if (!accounts.totalCount) {
        setCurrentAccountId(undefined);
        setCurrentAccount(undefined);
      } else if (!currentAccountId || !accounts.items.some(account => account.userId === currentAccountId)) {
        setCurrentAccountId(accounts.items[0].userId);
        setCurrentAccount(accounts.items[0]);
      } else if (currentAccountId !== currentAccount?.userId) {
        setCurrentAccount(accounts.items.find(m => m.userId === currentAccountId));
      }
    }
  }, [accounts, currentAccountId, setCurrentAccountId]);

  return {
    currentAccount,
    setCurrentAccount: (newCurrentAccount: AccountAuthorisationDto) => {
      setCurrentAccountId(newCurrentAccount?.userId);

      // Refresh on account change
      // FIXME: should also work on other tabs (useEffect?)
      if (newCurrentAccount !== currentAccount) {
        queryClient.clear();
        navigate(window.location, { replace: true });
      }
    },
  };
};
