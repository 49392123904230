import { useOidc } from '@axa-fr/react-oidc';
import IconChevron from '@iconify/icons-ic/baseline-keyboard-arrow-down';
import IconLogout from '@iconify/icons-material-symbols/logout';
import IconSettings from '@iconify/icons-material-symbols/settings-outline';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useGetUserOrganisationInformation } from '#edsn/api/pie-bff';
import { useCurrentOrganisation } from '#pie/auth';
import { Button } from '#pie/components/button/Button';
import { Icon } from '#pie/components/icon/Icon';
import { Text } from '#pie/components/text/Text';
import { UserAvatar } from '#pie/components/user-avatar/UserAvatar';
import { VITE_PUBLIC_URL } from '#pie/env';

export const HeaderProfile = () => {
  const { logout } = useOidc();

  const { data: allAccounts } = useGetUserOrganisationInformation();
  const { currentAccount, setCurrentAccount } = useCurrentOrganisation();

  if (!allAccounts || !currentAccount) {
    return null;
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button
          className="ml-auto flex flex-shrink-0 cursor-pointer items-center justify-between gap-4 text-left focus:outline-none"
          aria-label="Open profiel menu"
        >
          <UserAvatar showInfo />
          <Icon icon={IconChevron} size={16} />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          sideOffset={24}
          align="end"
          className="z-20 max-h-[503px] w-72 overflow-auto rounded-lg bg-white shadow-sm"
        >
          {allAccounts.items.map((company, idx) => {
            const isActive = company.ean13 === currentAccount.ean13;

            return isActive ? (
              <DropdownMenu.Item asChild key={idx}>
                <div className="bg-primary flex w-full  flex-col p-6 text-left text-white data-[highlighted]:outline-none">
                  <Text className="font-semibold">{company.name}</Text>
                  <Text variant="xs">{company.marketRole}</Text>

                  <div className="mt-4 flex justify-between">
                    <Button
                      as="a"
                      href="https://mijn.partnersinenergie.nl/nl-NL/profile/"
                      iconStart={IconSettings}
                      size="sm"
                      variant="ghostPrimary"
                    >
                      Instellingen
                    </Button>
                    <Button
                      aria-label="Uitloggen"
                      className="text-white"
                      onClick={() => logout(VITE_PUBLIC_URL)}
                      size="sm"
                    >
                      <Icon icon={IconLogout} />
                    </Button>
                  </div>
                </div>
              </DropdownMenu.Item>
            ) : (
              <DropdownMenu.Item asChild key={idx}>
                <button
                  onClick={() => setCurrentAccount(company)}
                  aria-label={`Selecteer organisatie ${company.name}`}
                  className="flex w-full cursor-pointer flex-col border-b border-b-neutral-200 p-6 text-left data-[highlighted]:bg-neutral-200 data-[highlighted]:outline-none"
                >
                  <Text className="text-primary-dark font-bold">{company.name}</Text>
                  <Text variant="xs" className="text-neutral-500">
                    {company.marketRole}
                  </Text>
                </button>
              </DropdownMenu.Item>
            );
          })}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
