import {
  type AccountAuthorisationDto,
  type AccountAuthorisationDtoListResultDto,
  AuthorizationRoles,
  type SelectDto,
} from '../generated/model';
import { generateEan } from './connection';

export const createSubOrganisation = (overrides: Partial<SelectDto> = {}): SelectDto => ({
  id: '1',
  label: 'Sub Organisation',
  ...overrides,
});

export const createOrganizationInformation = (): AccountAuthorisationDtoListResultDto => ({
  items: [
    createAccount(),
    createAccount({
      ean13: generateEan(13, 'user 1'),
      marketRole: 'PV',
      name: 'Rendo',
      roles: [],
      userId: '2',
    }),
    createAccount({ ean13: generateEan(13, 'user 2'), marketRole: 'MV', name: 'Test', userId: '3' }),
    createAccount({
      ean13: generateEan(13, 'user 3'),
      name: 'Admin',
      roles: [
        AuthorizationRoles.Tickets,
        AuthorizationRoles.CprRead,
        AuthorizationRoles.KnowledgeArticles,
        AuthorizationRoles.Admin,
        AuthorizationRoles.CprAdmin,
      ],
      userId: '4',
    }),
  ],
  totalCount: 2,
});

export const createAccount = (overrides: Partial<AccountAuthorisationDto> = {}): AccountAuthorisationDto => ({
  ean13: generateEan(13, 'user'),
  marketRole: 'LV',
  name: 'EDSN',
  roles: [
    AuthorizationRoles.Tickets,
    AuthorizationRoles.CprRead,
    AuthorizationRoles.KnowledgeArticles,
    AuthorizationRoles.CprAdmin,
  ],
  subOrganisations: [createSubOrganisation(), createSubOrganisation({ id: '2', label: 'Sub Organisation 2' })],
  userId: '1',
  ...overrides,
});
