import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getCprManagementContact } from '#edsn/api/pie-bff';
import { AddCprContactModal } from '#pie/components/add-cpr-contact-modal/AddCprContactModal';
import { Card } from '#pie/components/card/Card';
import { DataTable } from '#pie/components/data-table/DataTable';
import { InputSearch } from '#pie/components/input-search/InputSearch';
import { Page, PageHeader } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { RemoveCprContactButton } from '#pie/components/remove-cpr-contact-button/RemoveCprContactButton';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { useDataTable } from '#pie/hooks/useDataTable';
import { cprManagementContactDetailSortingAtom } from '#pie/stores/cprManagementContactDetail';
import { searchAtom } from '#pie/stores/search';
import { emptyFilterAtom } from '#pie/stores/searchOnlyFilter';

export const ContactManagementDetail = () => {
  const { t } = useTranslation();
  const contactGroupId = useParams()['contactGroupId']!;

  const tableProps = useDataTable({
    columns: helper => [
      helper.accessor('name', {
        header: t('common.name'),
      }),
      helper.accessor('email', {
        header: t('common.email'),
      }),
      helper.accessor('organisation.name', {
        cell: info => (
          <div className="flex">
            <Text>{info.getValue()}</Text>
            <RemoveCprContactButton data={info.row.original} />
          </div>
        ),
        header: t('common.organisation'),
      }),
    ],
    dateKeys: [],
    filtersAtom: emptyFilterAtom,
    hardFilters: { contactGroupIds: [contactGroupId] },
    query: getCprManagementContact,
    sortingAtom: cprManagementContactDetailSortingAtom,
  });

  const [search, setSearch] = useAtom(searchAtom);

  return (
    <>
      <PageHead
        title={t('cpr_contact_group_detail.header.title', {
          contactGroup: tableProps?.data?.length ? tableProps.data[0].contactGroup.name : '',
        })}
      />
      <Page>
        <PageHeader
          title={t('cpr_contact_group_detail.header.title', {
            contactGroup: tableProps?.data?.length ? tableProps.data[0].contactGroup.name : '',
          })}
          className="mb-8"
          backHref={`../contactgroepenbeheer`}
          previousText={t('cpr_detail.button.back')}
        >
          <AddCprContactModal contactGroupId={contactGroupId} />
        </PageHeader>
        <div className="flex items-start gap-6">
          <Card className="flex-1 overflow-x-hidden p-4">
            <Stack gap="md">
              <header className="px-3" role="toolbar">
                <Stack direction="row" gap="md" className="items-center">
                  <InputSearch
                    value={search || ''}
                    onChange={e => setSearch(e.target.value)}
                    placeholder={t('common.search.placeholder')}
                    onClear={() => setSearch('')}
                  />
                </Stack>
              </header>
              <DataTable {...tableProps} idKey="id" />
            </Stack>
          </Card>
        </div>
      </Page>
    </>
  );
};
