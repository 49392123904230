import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ManagementContactFilter } from '#edsn/api/pie-bff';
import { useGetManagementContact } from '#edsn/api/pie-bff';
import { FormCombobox } from '../form/form-combobox/FormCombobox';
import type { Props as InputComboboxProps } from '../input-combobox/InputCombobox';
import type { ComponentProps } from 'react';

interface Props extends Omit<Partial<ComponentProps<typeof FormCombobox>>, 'options' | 'name'> {
  label: string;
  name?: string;
  filter: ManagementContactFilter;
}

export const FormContactCombobox = ({ name = '', filter, ...props }: Props) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string | undefined>(props.defaultValue);
  const { data, isLoading } = useGetManagementContact({
    filter,
    pagination: {
      pageIndex: 0,
      pageSize: 20,
    },
    search,
  });

  const options: InputComboboxProps['options'] = data?.items?.map(item => ({
    label: item.fullName,
    value: item.id,
  }));

  return (
    <FormCombobox
      name={name}
      options={options}
      onSearch={setSearch}
      isLoading={isLoading}
      shouldFilter={false}
      placeholder={t('cpr_contact_group_detail.add_user.contact.placeholder')}
      {...props}
    />
  );
};
