import arrowDropDownIcon from '@iconify/icons-material-symbols/arrow-drop-down-rounded';
import arrowDropUpIcon from '@iconify/icons-material-symbols/arrow-drop-up-rounded';
import {
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { Icon } from '../icon/Icon';
import { InputCheckbox } from '../input-checkbox/InputCheckbox';
import { Skeleton } from '../skeleton/Skeleton';
import { Pagination } from './Pagination';
import type { Optional } from '#pie/types';
import type {
  PaginationState,
  RowData,
  RowSelectionState,
  SortingState,
  TableOptions,
  Updater,
} from '@tanstack/react-table';
import { cn, tv } from '#pie/utils/TailwindUtils';

export interface TableProps<T extends RowData>
  extends Optional<Omit<TableOptions<T>, 'getCoreRowModel' | 'manualPagination' | 'enableGrouping' | 'state'>, 'data'> {
  className?: string;
  emphasizedRows?: string[];
  pagination: PaginationState;
  pageSizes?: number[];
  sorting: SortingState;
  rowSelection?: RowSelectionState;
  totalCount?: number;
  isLoading?: boolean | number;
  label?: string;
}

export const useTableState = ({
  pageSizes,
  defaultSort,
  defaultPagination,
}: {
  pageSizes: number[];
  defaultSort?: SortingState;
  defaultPagination?: PaginationState;
}) => {
  const [pagination, setPagination] = useState<PaginationState>(
    defaultPagination || {
      pageIndex: 0,
      pageSize: Math.min(...pageSizes),
    }
  );
  const [sorting, setSorting] = useState<SortingState>(defaultSort || []);

  const onPaginationChange = (paginationUpdater: Updater<PaginationState>) => {
    const newPaginationVal = functionalUpdate(paginationUpdater, pagination);
    setPagination(newPaginationVal);
  };

  const onSortingChange = (sortingUpdater: Updater<SortingState>) => {
    const newSortingVal = functionalUpdate(sortingUpdater, sorting);
    setSorting(newSortingVal);
  };

  return {
    onPaginationChange,
    onSortingChange,
    pagination,
    sorting,
  };
};

export function Table<T extends RowData>({
  data,
  emphasizedRows = [],
  pageSizes,
  pagination,
  rowSelection,
  sorting,
  totalCount,
  isLoading,
  label,
  ...props
}: TableProps<T>) {
  const styles = tableStyles();

  const tableInstance = useReactTable({
    data: data ?? [],
    enableGrouping: false,
    enableRowSelection: !!props.onRowSelectionChange && !!rowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    state: {
      pagination,
      sorting,
      ...(rowSelection ? { rowSelection } : {}),
    },
    ...props,
  });

  const smallestPageSize = pageSizes ? Math.min(...pageSizes) : 10;

  return (
    <div className={styles.container()}>
      <div className={styles.tableWrapper()}>
        <table className={styles.table()} aria-label={label}>
          <thead>
            {tableInstance.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {!!props.onRowSelectionChange && (
                  <td className={styles.td()}>
                    <InputCheckbox
                      checked={
                        tableInstance.getIsSomeRowsSelected() ? 'indeterminate' : tableInstance.getIsAllRowsSelected()
                      }
                      onCheckedChange={checked =>
                        tableInstance.getToggleAllRowsSelectedHandler()({ target: { checked } })
                      }
                    />
                  </td>
                )}
                {headerGroup.headers.map(header => (
                  <th key={header.id} className={styles.th()}>
                    {!!props.onSortingChange && (
                      <button
                        type="button"
                        className="flex flex-row text-left"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <Icon icon={arrowDropUpIcon} className={styles.sorting()} />,
                          desc: <Icon icon={arrowDropDownIcon} className={styles.sorting()} />,
                        }[header.column.getIsSorted() as string] ?? <Icon icon={arrowDropDownIcon} className="ml-2" />}
                      </button>
                    )}
                    {!props.onSortingChange && flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {isLoading
              ? new Array(typeof isLoading === 'number' ? isLoading : pagination.pageSize)
                  .fill(null)
                  .map((_, rowIndex) => (
                    <tr key={`row${rowIndex}`} className={cn(styles.base({ isChecked: false }), styles.tr())}>
                      {!!props.onRowSelectionChange && (
                        <td className={styles.td()}>
                          <InputCheckbox disabled />
                        </td>
                      )}
                      {tableInstance.getHeaderGroups()[0].headers.map((_, cellIndex) => (
                        <td key={`cell${cellIndex}`} className={styles.td()}>
                          <Skeleton />
                        </td>
                      ))}
                    </tr>
                  ))
              : tableInstance.getRowModel().rows.map(row => (
                  <tr
                    key={row.id}
                    data-testid={`table-row-${row.id}`}
                    className={cn(
                      styles.base({
                        emphasis: emphasizedRows.includes(row.id),
                        isChecked: row.getIsSelected(),
                      }),
                      styles.tr()
                    )}
                  >
                    {!!props.onRowSelectionChange && (
                      <td className={cn(styles.td(), 'border- relative z-10')}>
                        <InputCheckbox
                          checked={row.getIsSelected()}
                          disabled={!row.getCanSelect()}
                          onCheckedChange={checked => row.getToggleSelectedHandler()({ target: { checked } })}
                        />
                      </td>
                    )}
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id} className={styles.td()}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      {!!props.onPaginationChange &&
        ((isLoading && typeof isLoading === 'number' ? isLoading >= smallestPageSize : isLoading) ||
          (totalCount || 0) > smallestPageSize) && (
          <Pagination pageSizes={pageSizes} table={tableInstance} totalCount={totalCount} isLoading={!!isLoading} />
        )}
    </div>
  );
}

export const tableStyles = tv({
  base: '',
  slots: {
    container: 'bg-fit rounded space-y-4',
    sorting: 'bg-primary-lighter rounded-full ml-2 text-primary-light',
    table: 'min-w-full',
    tableWrapper: 'overflow-x-auto',
    td: 'py-2 px-3 whitespace-nowrap',
    th: 'text-left first:text-center py-2 px-3 whitespace-nowrap text-sm font-bold',
    tr: 'hover:shadow-[0_4px_32px_-10px_rgba(0,0,0,0.2)] h-[48px] relative border-l border-l-[transparent]',
  },
  variants: {
    emphasis: {
      true: 'font-bold',
    },
    isChecked: {
      false: 'border-y border-neutral-200',
      true: { base: 'bg-primary-lighter border border-double border-primary-light', tr: 'border-l-primary-light' },
    },
  },
});
