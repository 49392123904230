import { useOidc } from '@axa-fr/react-oidc';
import type { AuthorizationRoles } from '#edsn/api/pie-bff';
import { useGetUserOrganisationInformation } from '#edsn/api/pie-bff';
import { hasRole } from '#edsn/auth';
import { useCurrentOrganisation } from '.';

export const useUserHasRole = ({
  role,
  marketRole,
}: {
  role?: AuthorizationRoles;
  marketRole?: string;
}): undefined | boolean => {
  const { isAuthenticated } = useOidc();
  const { isLoading } = useGetUserOrganisationInformation({ query: { enabled: isAuthenticated } });
  const { currentAccount } = useCurrentOrganisation();

  if (isLoading) return undefined;

  if (!isAuthenticated || !currentAccount) {
    return false;
  }

  /* c8 ignore next */
  const hasRequiredRole = !role || hasRole(role, currentAccount.roles);
  const hasRequiredMarketRole = !marketRole || hasRole(marketRole, [currentAccount.marketRole]);

  return hasRequiredRole && hasRequiredMarketRole;
};
