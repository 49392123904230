import { OidcSecure, useOidc } from '@axa-fr/react-oidc';
import { type PropsWithChildren, lazy } from 'react';
import { Navigate, Outlet, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { AuthorizationRoles, useGetUserOrganisationInformation } from '#edsn/api/pie-bff';
import { hasRole } from '#edsn/auth';
import { useCurrentOrganisation } from './auth';
import { AuthLoading } from './components/auth-loading/AuthLoading';
import { Button } from './components/button/Button';
import { AuthError } from './pages/auth-error/AuthError';
import { AuthExpired } from './pages/auth-expired/AuthExpired';
import { ClearCache } from './pages/clear-cache/ClearCache';
import { ContactGroupDetail } from './pages/contact-person-register/ContactGroupDetail';
import { ContactManagementDetail } from './pages/contact-person-register/ContactManagementDetail';
import { ContactPersonRegister } from './pages/contact-person-register/ContactPersonRegister';
import { ContactPersonRegisterOrganisationDetail } from './pages/contact-person-register/OrganisationDetail';
import { ErrorBoundary } from './pages/error-boundary/ErrorBoundary';
import { ErrorPage } from './pages/error-page/ErrorPage';
import { Home } from './pages/home/Home';
import { KnowledgeBase } from './pages/knowledge-base/KnowledgeBase';
import { KnowledgeBaseDetail } from './pages/knowledge-base-detail/KnowledgeBaseDetail';
import { ReleaseNotes } from './pages/release-notes/ReleaseNotes';
import { SurveyDialogue } from './pages/survey-dialogue/SurveyDialogue';
import { SurveyDialogueDetail } from './pages/survey-dialogue-detail/SurveyDialogueDetail';
import { TicketCreateEan } from './pages/ticket-create/ean/TicketCreateEan';
import { TicketCreateNonConformity } from './pages/ticket-create/non-conformity/TicketCreateNonConformity';
import { TicketCreateOtherDetails } from './pages/ticket-create/other/TicketCreateOtherDetails';
import { TicketCreateSelectType } from './pages/ticket-create/TicketCreateSelectType';

const Layout = lazy(() => import('./components/layout/Layout').then(module => ({ default: module.Layout })));
const NewsArchive = lazy(() =>
  import('./pages/news-archive/NewsArchive').then(module => ({ default: module.NewsArchive }))
);
const NewsDetail = lazy(() =>
  import('./pages/news-detail/NewsDetail').then(module => ({ default: module.NewsDetail }))
);
const Tickets = lazy(() => import('./pages/tickets/Tickets').then(module => ({ default: module.Tickets })));
const TicketDetail = lazy(() =>
  import('./pages/ticket-detail/TicketDetail').then(module => ({ default: module.TicketDetail }))
);

const AuthGuard = ({
  children,
  roles = [],
  marketRoles = [],
}: { roles?: AuthorizationRoles[]; marketRoles?: string[] } & PropsWithChildren) => {
  const { logout } = useOidc();
  const { isLoading } = useGetUserOrganisationInformation();
  const { currentAccount } = useCurrentOrganisation();

  if (isLoading) {
    return <AuthLoading />;
  }

  if (!currentAccount) {
    return (
      <ErrorPage
        heading="Geen toegang"
        message={
          <div>
            Er zijn geen organisaties gekoppeld aan de ingelogde gebruiker. Klik{' '}
            <Button variant="link" aria-label="Uitloggen" onClick={() => logout()} size="sm">
              hier
            </Button>{' '}
            om uit te loggen.
          </div>
        }
      />
    );
  }

  gtag('set', 'organisation', {
    name: currentAccount.name,
  });

  return hasRole(roles, currentAccount.roles) && hasRole(marketRoles, [currentAccount.marketRole]) ? (
    children
  ) : (
    <ErrorPage heading="Geen toegang" message="Je hebt niet de juiste rechten om deze pagina te bekijken." />
  );
};

export const createRouter = () =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route
        path=""
        element={
          <OidcSecure>
            <AuthGuard>
              <Outlet />
            </AuthGuard>
          </OidcSecure>
        }
        errorElement={<ErrorBoundary />}
      >
        <Route element={null} path="oauth/handler" />
        <Route element={<Layout />} path="">
          <Route index element={<Home />} />
          <Route
            element={
              <AuthGuard roles={['KnowledgeArticles']}>
                <Outlet />
              </AuthGuard>
            }
            path="kennisbank"
          >
            <Route index element={<KnowledgeBase />} />
            <Route element={<KnowledgeBaseDetail />} path=":id" />
          </Route>
          <Route path="nieuws">
            <Route element={<NewsArchive />} index />
            <Route element={<NewsDetail />} path=":slug" />
          </Route>
          <Route
            element={
              <AuthGuard roles={['Tickets']}>
                <Outlet />
              </AuthGuard>
            }
            path="tickets"
          >
            <Route element={<Tickets />} index />
            <Route path="nieuw">
              <Route element={<TicketCreateSelectType />} index />
              <Route path="ean" element={<TicketCreateEan />} />
              <Route
                path="non-conformity"
                element={
                  <AuthGuard marketRoles={['MV']}>
                    <TicketCreateNonConformity />
                  </AuthGuard>
                }
              />
              <Route element={<TicketCreateOtherDetails />} path="other" />
            </Route>

            <Route element={<TicketDetail />} path=":id" />
          </Route>
          <Route
            element={
              <AuthGuard roles={['Tickets']}>
                <Outlet />
              </AuthGuard>
            }
            path="ticketfeedback"
          >
            <Route element={<SurveyDialogue />} index />
            <Route element={<SurveyDialogueDetail />} path=":id" />
          </Route>
          <Route path="contactpersonenregister" element={<Outlet />}>
            <Route
              path=""
              element={
                <AuthGuard roles={[AuthorizationRoles.CprRead]}>
                  <Outlet />
                </AuthGuard>
              }
            >
              <Route index element={<Navigate to="contact" />} />
              <Route element={<ContactPersonRegister />} path="contact" />
              <Route element={<ContactPersonRegister />} path="organisatie" />
              <Route element={<ContactPersonRegisterOrganisationDetail />} path="organisatie/:organisationId" />
              <Route element={<ContactPersonRegister />} path="contactgroep"></Route>
              <Route element={<ContactGroupDetail />} path="contactgroep/:contactGroupId" />
            </Route>
            <Route
              path=""
              element={
                <AuthGuard roles={[AuthorizationRoles.CprAdmin]}>
                  <Outlet />
                </AuthGuard>
              }
            >
              <Route element={<ContactPersonRegister />} path="contactgroepenbeheer"></Route>
              <Route element={<ContactManagementDetail />} path="contactgroepenbeheer/:contactGroupId" />
              <Route element={<ContactPersonRegister />} path="contactpersonenbeheer" />
            </Route>
          </Route>
          <Route element={<ReleaseNotes />} path="release-notes" />
          <Route element={<ClearCache />} path="clear-cache" />
          <Route element={<AuthError />} path="/auth-error" />
          <Route element={<AuthExpired />} path="/auth-expired" />
        </Route>
      </Route>
    )
  );
