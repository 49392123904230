import IconRight from '@iconify/icons-material-symbols/chevron-right';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/logo.svg?react';
import { FeedbackDialog } from './feedback-dialog/FeedbackDialog';
import { SidebarMenu } from './menu/SidebarMenu';
import type { HTMLAttributes } from 'react';
import { Icon } from '#pie/components/icon/Icon';
import { Text } from '#pie/components/text/Text';
import { useProtectedMenu } from '#pie/hooks/useProtectedMenu';
import { cn, tv } from '#pie/utils/TailwindUtils';

interface SidebarProps extends HTMLAttributes<HTMLDivElement> {
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
}

export const Sidebar = ({ isOpen, setIsOpen, className }: SidebarProps) => {
  const { t } = useTranslation();
  const s = styles({ isOpen });

  const { mainMenu } = useProtectedMenu();
  return (
    <div id="sidebar" className={cn(s.base(), className)}>
      <Link data-testid="logo" to="/" className={s.logo()} title={t('home.page_title')}>
        <Logo className="max-w-[68px]" />
      </Link>

      <button
        aria-controls="sidebar"
        aria-expanded={isOpen ? 'true' : 'false'}
        onClick={() => setIsOpen?.(!isOpen)}
        className={s.toggle()}
        title={isOpen ? 'Sluit sidebar' : 'Open sidebar'}
      >
        <Icon icon={IconRight} size={12} />
      </button>

      <SidebarMenu menu={mainMenu} isOpen={isOpen} />

      <FeedbackDialog isOpen={isOpen} />

      <Text variant="xs" className={s.beta()}>
        Beta
      </Text>
    </div>
  );
};

export const styles = tv({
  slots: {
    base: 'relative h-full bg-primary flex-shrink-0 z-10 flex flex-col',
    beta: 'ml-auto px-2 bg-success text-white uppercase text-center w-full',
    logo: 'min-h-[73px] h-[73px] flex text-white items-center focus:outline-none',
    toggle:
      'z-10 bg-primary-dark absolute right-0 top-[84px] grid h-12 w-3 translate-x-3 place-items-center text-white rounded-r-md',
  },

  variants: {
    isOpen: {
      false: {
        base: 'w-[64px]',
        logo: 'mx-4',
      },

      true: {
        base: 'w-[240px] shadow-sm',
        logo: 'mx-4',
        toggle: 'rotate-180 rounded-l-md rounded-r-none',
      },
    },
  },
});
