import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon, DashIcon } from '@radix-ui/react-icons';
import { forwardRef } from 'react';
import type { VariantProps } from '#pie/utils/TailwindUtils';
import { tv } from '#pie/utils/TailwindUtils';

export interface InputCheckboxProps
  extends Omit<Checkbox.CheckboxProps, 'onChange' | 'value'>,
    Omit<VariantProps<typeof inputCheckbox>, 'checked'> {
  label?: string;
  onChange?: (value?: string) => void;
  value?: string;
}

export const InputCheckbox = forwardRef<HTMLButtonElement, InputCheckboxProps>(function InputCheckbox(
  { label, isError, onCheckedChange, onChange, ...props },
  ref
) {
  const { disabled, id, value, checked } = props;
  const styles = inputCheckbox({ checked: !!checked, disabled, isError });
  const Icon = checked === 'indeterminate' ? DashIcon : CheckIcon;

  return (
    <div className={styles.wrapper()}>
      <Checkbox.Root
        ref={ref}
        className={styles.root()}
        onCheckedChange={checkedState => {
          onCheckedChange?.(checkedState);
          onChange?.(checkedState !== false ? value : undefined);
        }}
        aria-label={label}
        {...props}
      >
        <Checkbox.Indicator className="flex">
          <Icon className={styles.checker()} />
        </Checkbox.Indicator>
      </Checkbox.Root>
      {label && (
        <label className={styles.label()} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
});

const inputCheckbox = tv({
  slots: {
    checker: 'text-white ',
    label: 'text-neutral-600 cursor-pointer',
    root: 'focus-visible:outline-primary focus-visible:outline-2 flex items-center justify-center rounded-[4px] w-4 h-4 bg-white data-[state=checked]:bg-primary-light shadow-inputEmphasis data-[state=checked]:outline-primary-lighter outline outline-1 outline-input border-none data-[state=checked]:focus-visible:outline-primary data-[state=checked]:shadow-none appearance-none data-[state=checked]:outline-2 data-[state=indeterminate]:outline-primary-lighter',
    wrapper: 'flex gap-2 items-center pl-px',
  },
  variants: {
    checked: {
      true: { root: 'bg-primary-light outline-primary-light focus-visible:outline-primary border-primary shadow-none' },
    },
    disabled: {
      true: {
        label: 'text-neutral-300 cursor-not-allowed',
      },
    },
    isError: {
      true: {
        label: 'text-error',
        root: 'data-[state=checked]:bg-error data-[state=checked]:outline-error bg-error-light outline-error',
      },
    },
  },
});
