import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { EffortScore, SurveySubject } from '#edsn/api/pie-bff';
import { useGetSurveyDialogue } from '#edsn/api/pie-bff';
import { Card } from '#pie/components/card/Card';
import { DataTable } from '#pie/components/data-table/DataTable';
import { Page, PageHeader } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { Stack } from '#pie/components/stack/Stack';
import { SurveyDialogueStatusTag } from '#pie/components/survey-dialogue-status-tag/SurveyDialogueStatusTag';
import { SurveyDialogueToolbar } from '#pie/components/survey-dialogue-toolbar/SurveyDialogueToolbar';
import { Text } from '#pie/components/text/Text';
import { getFormattedDate } from '#pie/i18n/dateFormats';
import { cesFiltersAtom, cesSortingAtom } from '#pie/stores/ces';
import { paginationAtom } from '#pie/stores/pagination';
import { parseDates } from '#pie/utils/date';

export const SurveyDialogue = () => {
  const { t } = useTranslation();

  const pagination = useAtomValue(paginationAtom);
  const sorting = useAtomValue(cesSortingAtom);
  const sortCol = sorting[0];
  const filter = useAtomValue(cesFiltersAtom);

  const { data, isLoading } = useGetSurveyDialogue(
    {
      filter,
      pagination,
      sorting: sortCol && { descending: sortCol.desc, field: sortCol.id },
    },
    {
      query: {
        select: data => ({
          ...data,
          items: data.items.map(item => ({ ...parseDates(item, ['createdOn']) })),
        }),
      },
    }
  );

  return (
    <>
      <PageHead title={t('survey_dialogue_overview.title')} />
      <Page>
        <PageHeader title={t('survey_dialogue_overview.title')} className="mb-8"></PageHeader>
        <Stack as="section" gap="lg">
          <Text className="max-w-[960px] whitespace-pre-wrap">{t('survey_dialogue_overview.description')}</Text>
          <Card className="flex-1 overflow-x-hidden p-4">
            <Stack gap="md">
              <SurveyDialogueToolbar />
              <DataTable
                data={data?.items}
                sortingAtom={cesSortingAtom}
                isLoading={isLoading}
                totalCount={data?.totalCount}
                columns={helper => [
                  helper.accessor('ticketNumber', {
                    cell: info => (
                      <>
                        <span>{info.getValue()}</span>
                        <Link
                          className="absolute inset-0"
                          to={`/ticketfeedback/${info.row.original.id}`}
                          aria-label={info.getValue()}
                        />
                      </>
                    ),
                    header: t('common.ticket_number'),
                  }),
                  helper.accessor('subject', {
                    cell: info => t(`survey_dialogue.subject.${info.getValue() as SurveySubject}`),
                    header: t('survey_dialogue_overview.table.header.subject'),
                  }),
                  helper.accessor('regionalGridOperatorHandler.label', {
                    header: t('survey_dialogue_overview.table.header.regional_grid_operator_handler'),
                  }),
                  helper.accessor('status', {
                    cell: info => {
                      const status = info.getValue();
                      return <SurveyDialogueStatusTag status={status} />;
                    },
                    header: t('survey_dialogue_overview.table.header.status'),
                    size: 120,
                  }),
                  helper.accessor('createdOn', {
                    cell: info => {
                      const date = info.getValue();
                      return date && getFormattedDate(date);
                    },
                    header: t('survey_dialogue_overview.table.header.created_on'),
                  }),
                  helper.accessor('effortScore', {
                    cell: info => t(`survey_dialogue.effort_score.${info.getValue() as EffortScore}`),
                    header: t('survey_dialogue_overview.table.header.effort_score'),
                  }),
                ]}
              />
            </Stack>
          </Card>
        </Stack>
      </Page>
    </>
  );
};
