import { useOidc } from '@axa-fr/react-oidc';
import { useTranslation } from 'react-i18next';
import { Button } from '#pie/components/button/Button';
import { PageHead } from '#pie/components/page-head/PageHead';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';

export const AuthExpired = () => {
  const { t } = useTranslation();
  const oidc = useOidc();

  return (
    <>
      <PageHead title={t('auth_expired.title')} noIndex />
      <Stack className="items-start px-8 py-4" gap="md">
        <Text as="h1" variant="h3" className="mb-4">
          {t('auth_expired.title')}
        </Text>
        <Text>{t('auth_expired.message')}</Text>
        <Button onClick={() => oidc.login('/', { prompt: 'login' })}>{t('common.login')}</Button>
      </Stack>
    </>
  );
};
