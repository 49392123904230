import { type ColumnHelper, createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { TicketListDto } from '#edsn/api/pie-bff';
import { CopyButton } from '../copy-button/CopyButton';
import { DataTable } from '../data-table/DataTable';
import { OverflowText } from '../overflow-text/OverflowText';
import type { SetTypeForFields } from '#pie/utils/date';
import type { ComponentProps } from 'react';
import { TicketStatusTag } from '#pie/components/ticket-status-tag/TicketStatusTag';
import { getFormattedDate } from '#pie/i18n/dateFormats';
import { ticketSortingAtom } from '#pie/stores/ticket';

type Translator = ReturnType<typeof useTranslation>['t'];

interface TicketTableProps
  extends Omit<ComponentProps<typeof DataTable<ParsedTicketListDto>>, 'columns' | 'sortingAtom'> {
  variant?: 'A' | 'B' | 'C' | 'D';
}

type ParsedTicketListDto = SetTypeForFields<
  TicketListDto,
  ['createdOn', 'eventDate', 'handledByRegionalGridOperatorDate', 'modifiedOn', 'processDate'],
  Date
>;

export const ticketsColumnParse = ({
  helper = createColumnHelper<ParsedTicketListDto>(),
  openNewWindow,
  t,
}: {
  helper?: ColumnHelper<ParsedTicketListDto>;
  openNewWindow?: boolean;
  t: Translator;
}) => ({
  createdOn: helper.accessor('createdOn', {
    cell: info => {
      const date = info.getValue();
      return date && getFormattedDate(date);
    },
    header: t('tickets_overview.table.header.created_on'),
  }),
  electricityEan: helper.accessor('electricityEan', {
    cell: info => {
      const value = info.getValue();
      return value ? (
        <CopyButton content={value} className="relative z-10">
          {value}
        </CopyButton>
      ) : (
        ''
      );
    },
    header: t('tickets_overview.table.header.electricity_ean'),
  }),
  eventDate: helper.accessor('eventDate', {
    cell: info => {
      const date = info.getValue();
      return date && getFormattedDate(date, false);
    },
    header: t('tickets_overview.table.header.event_date'),
  }),
  gasEan: helper.accessor('gasEan', {
    cell: info => {
      const value = info.getValue();
      return value ? (
        <CopyButton content={value} className="relative z-10">
          {value}
        </CopyButton>
      ) : (
        ''
      );
    },
    header: t('tickets_overview.table.header.gas_ean'),
  }),
  handledByRegionalGridOperatorDate: helper.accessor('handledByRegionalGridOperatorDate', {
    cell: info => {
      const date = info.getValue();
      return date && getFormattedDate(date);
    },
    header: t('tickets_overview.table.header.handled_by_regional_grid_operator_date'),
  }),
  handler: helper.accessor('handler.label', {
    header: t('tickets_overview.table.header.handler'),
  }),
  hasUnreadComments: helper.accessor('hasUnreadComments', {
    cell: info => (info.getValue() ? 'Nee' : 'Ja'),
    header: t('tickets_overview.table.header.has_unread_comments'),
  }),
  issuer: helper.accessor('issuer.label', {
    header: t('tickets_overview.table.header.issuer'),
  }),
  mainCategory: helper.accessor('mainCategory.label', {
    header: t('tickets_overview.table.header.main_category'),
  }),
  modifiedOn: helper.accessor('modifiedOn', {
    cell: info => {
      const date = info.getValue();
      return date && getFormattedDate(date);
    },
    header: t('tickets_overview.table.header.modified_on'),
  }),
  origin: helper.accessor('origin', {
    cell: origin => {
      const value = origin.getValue();
      return value ? t(`tickets_overview.common.origin.${value}`) : '';
    },
    header: t('tickets_overview.table.header.origin'),
  }),
  processDate: helper.accessor('processDate', {
    cell: info => {
      const value = info.getValue();
      return value ? format(value, 'yyyyMM') : '';
    },
    header: t('tickets_overview.table.header.process_date'),
  }),
  regionalGridOperator: helper.accessor('regionalGridOperator.label', {
    header: t('tickets_overview.table.header.regional_grid_operator'),
  }),
  status: helper.accessor('status', {
    cell: info => {
      const status = info.getValue();
      return <TicketStatusTag status={status} />;
    },
    header: t('tickets_overview.table.header.status'),
  }),
  subCategory: helper.accessor('subCategory.label', {
    header: t('tickets_overview.table.header.sub_category'),
  }),
  ticketNumber: helper.accessor('ticketNumber', {
    cell: info => (
      <>
        <Link to={`/tickets/${info.row.original.id}`} target={openNewWindow ? '_blank' : undefined}>
          <CopyButton content={info.getValue()} className="relative z-10">
            {info.getValue()}
          </CopyButton>
        </Link>
        <Link
          className="absolute inset-0"
          to={`/tickets/${info.row.original.id}`}
          target={openNewWindow ? '_blank' : undefined}
          aria-label={info.getValue()}
        />
      </>
    ),
    header: t('common.ticket_number'),
  }),
  title: helper.accessor('title', {
    cell: info => <OverflowText maxWidth={200}>{info.getValue()}</OverflowText>,
    header: t('tickets_overview.table.header.title'),
  }),
});

export type TicketsColumnName = keyof ReturnType<typeof ticketsColumnParse>;

export function TicketTable({ variant = 'A', ...props }: TicketTableProps) {
  const { t } = useTranslation();

  const aColumns: TicketsColumnName[] = [
    'ticketNumber',
    'title',
    'status',
    'hasUnreadComments',
    'origin',
    'regionalGridOperator',
    'issuer',
    'handler',
    'createdOn',
    'modifiedOn',
    'eventDate',
    'mainCategory',
    'subCategory',
    'electricityEan',
    'gasEan',
  ];
  const bColumns: TicketsColumnName[] = [
    'ticketNumber',
    'title',
    'status',
    'hasUnreadComments',
    'regionalGridOperator',
    'issuer',
    'handler',
    'mainCategory',
    'createdOn',
    'handledByRegionalGridOperatorDate',
  ];
  const cColumns: TicketsColumnName[] = [
    'ticketNumber',
    'title',
    'status',
    'hasUnreadComments',
    'processDate',
    'origin',
    'regionalGridOperator',
    'issuer',
    'handler',
    'createdOn',
    'modifiedOn',
    'subCategory',
  ];
  const dColumns: TicketsColumnName[] = ['ticketNumber', 'title', 'regionalGridOperator', 'status', 'modifiedOn'];

  return (
    <DataTable
      sortingAtom={ticketSortingAtom}
      columns={helper =>
        ({ A: aColumns, B: bColumns, C: cColumns, D: dColumns })[variant].map(
          columnName => ticketsColumnParse({ helper: helper, t })[columnName]
        )
      }
      {...props}
    />
  );
}
