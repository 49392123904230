import { useGetKnowledgeArticle } from '#edsn/api/pie-bff';

export const useRelatedKnowledgeArticles = (mainCategoryId?: string | string[], subCategoryId?: string | string[]) => {
  const { data: relatedArticlesData, isFetching: relatedArticlesLoading } = useGetKnowledgeArticle(
    {
      filter: {
        mainCategoryIds: Array.isArray(mainCategoryId) ? mainCategoryId : [mainCategoryId!],
        subCategoryIds: subCategoryId ? (Array.isArray(subCategoryId) ? subCategoryId : [subCategoryId]) : undefined,
      },
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    {
      query: {
        enabled: !!mainCategoryId && mainCategoryId.length > 0,
      },
    }
  );
  return { relatedArticles: relatedArticlesData?.items, relatedArticlesLoading };
};
