import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useGetConnectionInformation } from '#edsn/api/pie-bff';
import { useTicketCategoryIdKV } from '../../../hooks/useTicketCategoryIdKV';
import { TicketAssigneeCard, assigneeValidationSchema } from '../TicketAssigneeCard';
import { TicketAttachmentFields, attachmentValidationSchema } from '../TicketAttachmentFields';
import { TicketCategoriesFields, categoriesValidationSchema } from '../TicketCategoriesFields';
import { TicketConnectionCard } from '../TicketConnectionCard';
import { TicketGridOperatorsFields, gridOperatorValidationSchema } from '../TicketGridOperatorsFields';
import { TicketTemplateDialog } from '../TicketTemplateDialog';
import { useTemplate } from '../useTemplate';
import { useEanFormContext } from './TicketCreateEan';
import type { StepProps } from './TicketCreateEan';
import { Card } from '#pie/components/card/Card';
import { ConnectionInfo } from '#pie/components/connection-info/ConnectionInfo';
import { Divider } from '#pie/components/divider/Divider';
import { EanFailure } from '#pie/components/ean-failure/EanFailure';
import { validation } from '#pie/components/form/formHelpers';
import { Page, PageHeader } from '#pie/components/page/Page';
import { RelatedKnowledgeArticlesCard } from '#pie/components/related-knowledge-articles-card/RelatedKnowledgeArticlesCard';
import { Skeleton } from '#pie/components/skeleton/Skeleton';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { useRelatedKnowledgeArticles } from '#pie/hooks/useRelatedKnowlegdeArticles';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const schema = (t: ReturnType<typeof useTranslation>['t']) =>
  z
    .object({
      description: z.string().max(2000).optional(),
      eventDate: z.string().optional(),
      title: z.string().min(1).max(100),
    })
    .merge(gridOperatorValidationSchema)
    .merge(categoriesValidationSchema)
    .merge(assigneeValidationSchema)
    .merge(attachmentValidationSchema(t));

type DetailsForm = z.infer<ReturnType<typeof schema>>;

const { FormDate, FormText, FormTextArea } = typedFormFields<DetailsForm>();

export const TicketCreateEanDetails = ({ wizardActions, onSubmit, onBack, stepper }: StepProps) => {
  const { t } = useTranslation();
  const { data, setStepShouldBlock } = useEanFormContext();

  const { data: elecData, isLoading: isElecLoading } = useGetConnectionInformation(
    { ean18: data.electricityEan! },
    { query: { enabled: !!data.electricityEan } }
  );
  const { data: gasData, isLoading: isGasLoading } = useGetConnectionInformation(
    { ean18: data.gasEan! },
    { query: { enabled: !!data.gasEan } }
  );

  const categoryIdKV = useTicketCategoryIdKV();

  const resolver = zodResolver(
    schema(t).refine(check => check.mainCategoryId !== categoryIdKV || check.eventDate, {
      message: t('zod.errors.invalid_event_date'),
      path: ['eventDate'],
    })
  );
  const defaultValues = useMemo(
    () => ({
      ...data,
      regionalGridOperatorId:
        elecData?.regionalGridOperator?.id || gasData?.regionalGridOperator?.id || data.regionalGridOperatorId,
    }),
    [elecData?.regionalGridOperator?.id, gasData?.regionalGridOperator?.id]
  );
  const formMethods = useForm<DetailsForm>({
    defaultValues,
    resolver,
  });
  const {
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { isDirty },
  } = formMethods;

  const category = watch('mainCategoryId');
  const subCategory = watch('subCategoryId');
  const shouldShowEventDate = category === categoryIdKV;

  const { showConfirm, onConfirmClick, closeConfirm } = useTemplate<ReturnType<typeof schema>>({
    getValues,
    setValue,
    watch,
  });

  useEffect(() => {
    if (!shouldShowEventDate) {
      reset({
        ...getValues(),
        eventDate: undefined,
      });
    }
  }, [getValues, reset, shouldShowEventDate]);

  useEffect(() => {
    if (isDirty) setStepShouldBlock(true);
  }, [isDirty]);

  const { relatedArticles, relatedArticlesLoading } = useRelatedKnowledgeArticles(category, subCategory);

  return (
    <Page>
      <PageHeader
        title={t('ticket_create.ean.page_title')}
        backOnClick={onBack}
        previousText={t('common.steps.previous')}
        details={stepper}
      />
      <TicketTemplateDialog closeConfirm={closeConfirm} onConfirmClick={onConfirmClick} showConfirm={showConfirm} />
      <FormProvider {...formMethods}>
        <form className="flex flex-col items-start gap-6 pb-8 md:flex-row" onSubmit={handleSubmit(onSubmit)}>
          <Card className="flex-[2] p-6" shadow>
            <Stack gap="lg">
              <EanFailure electricityEan={data.electricityEan} gasEan={data.gasEan} />
              <Stack gap="md">
                <Text as="h2" variant="h5">
                  {t('ticket_create.heading.ticket_about')}
                </Text>
                <TicketGridOperatorsFields disabled={!!defaultValues.regionalGridOperatorId} />
                <TicketCategoriesFields />
                {shouldShowEventDate && (
                  <FormDate
                    label={t('common.event_date')}
                    fullWidth
                    name="eventDate"
                    rules={{ required: validation.required }}
                  />
                )}
              </Stack>
              <Stack gap="md">
                <Text as="h2" variant="h5">
                  {t('ticket_create.heading.details')}
                </Text>
                <FormText
                  label={t('common.subject')}
                  fullWidth
                  name="title"
                  rules={{ required: validation.required }}
                  showWordCount={100}
                />
                <FormTextArea
                  label={t('common.description')}
                  fullWidth
                  name="description"
                  rows={8}
                  showWordCount
                  maxLength={2000}
                />
                <TicketAttachmentFields />
              </Stack>
              <Divider />
              <Stack direction="row" className="justify-between" gap="lg">
                {wizardActions}
              </Stack>
            </Stack>
          </Card>
          <Stack as="aside" gap="lg" className="flex-1">
            <TicketAssigneeCard />
            <TicketConnectionCard electricityEan={data.electricityEan} gasEan={data.gasEan} />
            {data?.electricityEan &&
              (isElecLoading ? (
                <Skeleton />
              ) : (
                elecData && <ConnectionInfo ean18={data.electricityEan} connection={elecData} />
              ))}
            {data?.gasEan &&
              (isGasLoading ? (
                <Skeleton className="h-14 rounded-md" />
              ) : (
                gasData && <ConnectionInfo ean18={data.gasEan} connection={gasData} />
              ))}
            {category && <RelatedKnowledgeArticlesCard articles={relatedArticles} isLoading={relatedArticlesLoading} />}
          </Stack>
        </form>
      </FormProvider>
    </Page>
  );
};
