import { OidcProvider } from '@axa-fr/react-oidc';
import { QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'jotai';
import { type PropsWithChildren, StrictMode, useMemo } from 'react';
import * as ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import SuperJSON from 'superjson';
import { DEFAULT_LOCALE, Locale, createQueryClient, initI18n } from '#edsn/shared';
import { initSentry } from '#edsn/shared/sentry';
import { AuthLoading } from './components/auth-loading/AuthLoading';
import { PageHead } from './components/page-head/PageHead';
import { loadStaticMessages } from './i18n/loadStaticMessages';
import { Translations } from './i18n/translations';
import { oidcConfig } from './oidcConfig';
import { createRouter } from './router';
import { store } from './stores';
import { getBatchLink, trpc } from './trpc';
import './index.css';
import { VITE_MOCK_CMS, VITE_SENTRY_DSN } from '#pie/env';

VITE_SENTRY_DSN &&
  initSentry({
    dsn: VITE_SENTRY_DSN,
  });

if (VITE_MOCK_CMS) {
  const worker = (await import('./mocks/browser')).worker;
  await worker.start({ onUnhandledRequest: 'bypass' });
}

const queryClient = createQueryClient();
initI18n(loadStaticMessages(queryClient), Locale.Dutch);

function TRPCProvider({ children }: PropsWithChildren) {
  const {
    i18n: { language },
  } = useTranslation();

  const locale = Object.values(Locale).includes(language as Locale) ? (language as Locale) : DEFAULT_LOCALE;
  const trpcClient = useMemo(
    () =>
      trpc.createClient({
        links: [getBatchLink({ locale, urlPathname: '' })],
        transformer: SuperJSON,
      }),
    [locale]
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <Translations>
      <TRPCProvider>
        <OidcProvider
          onEvent={(_, name, data) => {
            if (name === 'token_aquired' && data?.idTokenPayload?.acr?.includes('eherkenning')) {
              window.location.assign('/auth-error?eh=true');
            }
          }}
          configuration={oidcConfig}
          loadingComponent={() => null}
          authenticatingComponent={AuthLoading}
          callbackSuccessComponent={() => null}
          authenticatingErrorComponent={() => {
            window.location.assign('/auth-error');
            return null;
          }}
          sessionLostComponent={() => {
            window.location.assign('/auth-expired');
            return null;
          }}
        >
          <HelmetProvider>
            <Provider store={store}>
              <PageHead />
              <RouterProvider router={createRouter()} />
            </Provider>
          </HelmetProvider>
        </OidcProvider>
      </TRPCProvider>
    </Translations>
  </StrictMode>
);
