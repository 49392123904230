import { useController } from 'react-hook-form';
import type { ComponentProps } from 'react';
import type { FieldValues, UseControllerProps } from 'react-hook-form';
import { InputCheckbox } from '#pie/components/input-checkbox/InputCheckbox';
import { InputField } from '#pie/components/input-field/InputField';

type FormCheckboxProps<T extends FieldValues> = {
  options: Omit<ComponentProps<typeof InputCheckbox>, 'name'>[];
} & Pick<UseControllerProps<T>, 'disabled' | 'name' | 'rules' | 'defaultValue'> &
  Pick<ComponentProps<typeof InputField>, 'label'>;

export function FormCheckbox<T extends FieldValues>({
  name,
  disabled,
  rules,
  defaultValue,
  label,
  options,
  ...props
}: FormCheckboxProps<T>) {
  const {
    fieldState: { error },
    field: { onChange, ...field },
  } = useController({
    defaultValue,
    disabled,
    name,
    rules,
  });

  return (
    <InputField name={name} isRequired={!!rules?.required} label={label} error={error?.message} {...props}>
      {options.map(option => (
        <InputCheckbox
          key={`${name}-${option.value}`}
          id={name}
          isError={!!error}
          disabled={disabled}
          onChange={value => {
            value && !field.value?.includes(option.value)
              ? onChange([...(field.value || []), option.value])
              : !value &&
                field.value?.includes(option.value) &&
                onChange(field.value?.filter((v: string) => v !== option.value));
          }}
          {...field}
          {...option}
        />
      ))}
    </InputField>
  );
}
