import copyIcon from '@iconify/icons-material-symbols/content-copy-outline-rounded';
import { useCopyToClipboard } from 'usehooks-ts';
import { IconButton } from '../icon-button/IconButton';
import { useToast } from '../toast/ToastContext';
import type { ComponentProps, MouseEvent, PropsWithChildren } from 'react';
import { cn } from '#pie/utils/TailwindUtils';

interface Props extends PropsWithChildren, React.HTMLAttributes<HTMLSpanElement> {
  content: string;
  as?: ComponentProps<typeof IconButton>['as'];
}

export const CopyButton = ({ as, children, content, className, ...props }: Props) => {
  const [_, copy] = useCopyToClipboard();
  const { addToast } = useToast();

  const onCopy = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    copy(content);
    addToast({ message: `${content} is gekopieerd naar het klembord`, title: 'Gekopieeerd!', type: 'info' });
    gtag('event', 'copy');
  };

  return (
    <span className={cn('group', className)} {...props}>
      {children}
      <IconButton
        as={as}
        variant="primary"
        size={16}
        icon={copyIcon}
        aria-label={`${content} kopiëren naar klembord`}
        onClick={onCopy}
        className="relative z-20 ml-1 -translate-x-2 align-[-10%] opacity-0 transition group-hover:translate-x-0 group-hover:opacity-100"
      />
    </span>
  );
};
